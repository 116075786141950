<template>
   <div class="info">

   <a href="https://www.learn-apex.com/onboarding/email" target="_blank" >
        <img src="../../../assets/face-learn-apex.com.png" />
    </a>

    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>

    .ad{
        text-align: center;

        
    }

    .has-text-info{
        color:$alternative-background !important;
    }

   

    p{
        margin-bottom: 15px;
    }

    iframe{
        width: 160;
        height: 115;
    }

    .pure-center{
        margin-top:3%;
    }

    .fa-youtube{
        color:rgb(232, 120, 120);
    }

    

</style>